import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import propTypes from 'prop-types'
import * as API from '../../utilities/api'
import EntryForm from '../EntryForm'
import ErrorMessage from '../ErrorMessage'
import InfoMessage from '../InfoMessage'

import './styles.scss'

const Entry = ({
  history,
  card,
  handleSetCard,
  errors,
  setErrors,
  infoMessages,
}) => {
  const [previousEvents, setPreviousEvents] = useState([])
  const [relatedOperators, setRelatedOperators] = useState([])

  useEffect(() => {
    const getOperatorEvents = async () => {
      try {
        const res = await API.GET('api/events/')
        if (res && res.data && res.data.results) {
          const { data } = res
          const events = data.results
            .map((event) => ({
              value: event.id,
              label: event.name,
            }))
            .slice(-10)
            .reverse()
          setPreviousEvents(events)
        } else {
          setPreviousEvents([])
        }
      } catch (err) {
        setPreviousEvents([])
      }
    }
    getOperatorEvents()
  }, [])

  /*
  useEffect(() => {
    const getPreviousEvents = async () => {
      const res = await API.GET('api/entries/')
      if (res && res.data && res.data.results) {
        const { data } = res
        // Get unique previous event names, that
        // are not empty
        const eventNames = data.results
          .filter(item => item.event_name)
          .map(item => item.event_name)
        const uniqueEvents = [...new Set(eventNames)]
        const events = uniqueEvents
          .map(event => ({
            value: event,
            label: event,
          }))
          .slice(-10)
          .reverse()
        setPreviousEvents(events)
      } else {
        setPreviousEvents([])
      }
    }
    getPreviousEvents()
  }, [])
  */

  useEffect(() => {
    const getTicketDealerRelationships = async () => {
      const res = await API.GET('api/ticket_dealers/')
      if (res && res.data && res.data.results) {
        const { data } = res
        const cultureOperators = data.results.map((item) => ({
          value: item.culture_operator.id,
          label: item.culture_operator.name,
        }))

        if (cultureOperators && cultureOperators.length) {
          setRelatedOperators([...cultureOperators])
        }
      }
    }
    try {
      getTicketDealerRelationships()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }, [])

  const handleNavigateBack = () => {
    handleSetCard(null)
    setErrors({ ...errors, validation: null, expiration: null })
    history.push('/operator')
  }

  return (
    <main className="entry">
      <ErrorMessage msg={errors.expiration} />
      <InfoMessage msg={infoMessages.expiration} />
      <EntryForm
        redirect={() => history.push('/operator')}
        previousEvents={previousEvents}
        relatedOperators={relatedOperators}
        handleSetCard={handleSetCard}
        card={card}
        handleNavigateBack={handleNavigateBack}
        errors={errors}
        setErrors={setErrors}
      />
    </main>
  )
}

Entry.propTypes = {
  history: propTypes.instanceOf(Object).isRequired,
  handleSetCard: propTypes.func.isRequired,
  card: propTypes.shape({
    id: propTypes.number,
    card_number: propTypes.string,
  }),
  errors: propTypes.instanceOf(Object).isRequired,
  setErrors: propTypes.func.isRequired,
  infoMessages: propTypes.instanceOf(Object).isRequired,
}

Entry.defaultProps = {
  card: null,
}

export default withRouter(Entry)
