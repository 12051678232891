import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import './styles.scss'

const InfoMessage = ({ msg = '' }) => {
  const message = (
    <div className="info-message">
      <FontAwesomeIcon
        icon={faExclamationCircle}
        color="white"
        style={{ transform: 'rotate(180deg)' }}
      />
      <p className="message-body">{msg}</p>
    </div>
  )

  return msg ? message : null
}

export default InfoMessage
