import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import './styles.scss'

const SuccessMessage = ({ msg = '' }) => {
  const message = (
    <div className="success-message">
      <FontAwesomeIcon icon={faCheckCircle} color="white" />
      <p className="message-body">{msg}</p>
    </div>
  )

  return msg ? message : null
}

export default SuccessMessage
