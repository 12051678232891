/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Input } from 'react-rainbow-components'
import Scanner from '../Scanner'
import * as API from '../../utilities/api'
import ErrorMessage from '../ErrorMessage'

import './styles.scss'

const LoginView = ({ setResult, errors, setErrors }) => {
  const [showLoginForm, setShowLoginForm] = useState(true)
  const [infoText, setInfoText] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // Show login error message for 3 seconds
    const timer = setTimeout(() => {
      setErrors({})
    }, 3000)
    return () => clearTimeout(timer)
  }, [errors, setErrors])

  const handleTypedInLogin = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      const res = await API.POST('api-token-auth/', {
        email,
        password,
      })
      if (res && res.data && res.data.token) {
        const { token } = res.data
        // const { user } = res.data
        // const { operator } = user
        // const { operator_type: operatorType } = operator

        setResult(token)
        API.setAuthHeader(token)

        localStorage.setItem('kaikuToken', token)
        localStorage.setItem('kaikuEmail', email)
        // localStorage.setItem('kaikuOperator', operatorType)
      } else {
        console.log('something wrong', res)
        setErrors({
          login: 'Kirjautuminen epäonnistui. Tarkista tiedot!',
        })
        setLoading(false)
      }
    } catch (err) {
      setErrors({
        login: 'Kirjautuminen epäonnistui. Tarkista tiedot!',
      })
      setLoading(false)
    }
  }

  /*
  const handleQRLogin = async data => {
    try {
      console.log('logging in with scanned credentials', data)
      const [qrEmail, qrPassword] = data.split(';')
      if (qrEmail && qrPassword) {
        const res = await API.POST('api-token-auth/', {
          email: qrEmail,
          password: qrPassword,
        })
        console.log(res)
        if (res && res.data && res.data.token) {
          const { token } = res.data
          setResult(token)
          API.setAuthHeader(token)
          setLogin()
          setLoginInfo(email)
        } else {
          setErrors({
            login: 'Kirjautuminen epäonnistui. Tarkista tiedot!',
          })
        }
      } else {
        setErrors({
          login:
            'Qr koodista ei voida lukea kirjautumistietoja. Tarkista koodi!',
        })
      }
    } catch (err) {
      console.log('Error occurred:', err)
    }
  }
  */

  const handleQRScan = async (data) => {
    if (data) {
      setEmail(data)
      setShowLoginForm(true)
      setInfoText('Syötä vielä salasana!')
    }
  }

  return (
    <main className="login">
      {errors && (
        <div className="login-errors-container">
          <ErrorMessage msg={errors.login} />
        </div>
      )}
      {!showLoginForm && (
        <div className="login-with-scanner">
          <Scanner
            title="Lue kirjautumiskoodi"
            onRead={(value) => {
              setResult(value)
              handleQRScan(value)
            }}
          />
          {!showLoginForm && (
            <div className="divider-container">
              <div className="horizontal-line" />
              <h2>Tai</h2>
              <div className="horizontal-line" />
            </div>
          )}
        </div>
      )}

      <div className="login-with-form">
        <Button
          className="login-form-toggle-button rainbow-m-around_medium"
          type="button"
          variant="brand"
          onClick={() => setShowLoginForm(!showLoginForm)}
          label={
            showLoginForm ? 'Lue kirjautumiskoodi' : 'Syötä kirjautumistiedot'
          }
        />
        {showLoginForm && (
          <div className="divider-container">
            <div className="horizontal-line" />
            <h2>Tai</h2>
            <div className="horizontal-line" />
          </div>
        )}
        {showLoginForm && (
          <div className="login-form-container">
            <form
              className="login-form"
              onSubmit={(e) => handleTypedInLogin(e)}
            >
              {infoText && <h2 className="enter-password-text">{infoText}</h2>}
              <Input
                className="login-form-item"
                type="email"
                name="email"
                placeholder="sähköposti"
                aria-label="sähköposti"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Input
                className="login-form-item"
                type="password"
                name="password"
                placeholder="salasana"
                aria-label="salasana"
                value={password}
                style={{ border: `${infoText ? '2px solid #a0ccaf' : ''}` }}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                variant="brand"
                className="rainbow-m-around_medium login-form-toggle-button"
                aria-label="kirjaudu"
                type="submit"
                isLoading={loading}
                label="Kirjaudu"
              />
            </form>
          </div>
        )}
      </div>
    </main>
  )
}

LoginView.propTypes = {
  setResult: PropTypes.func.isRequired,
  errors: PropTypes.shape({ login: PropTypes.string }).isRequired,
  setErrors: PropTypes.func.isRequired,
}

export default LoginView
