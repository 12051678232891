/* eslint-disable no-console */
import React, { useState } from 'react'
import QrReader from 'react-qr-reader'
import PropTypes from 'prop-types'
import './styles.scss'

const Camera = ({ onRead }) => {
  const [result, setResult] = useState('')
  const [errors, setErrors] = useState(false)

  const handleScan = (data) => {
    if (data) {
      console.log('scanned data', data)
      setResult(data)
      onRead(data)
      setErrors(true)
    }
  }

  const handleError = () => {
    setErrors(true)
  }

  return (
    <div className="camera">
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        className="qr-image-wrapper"
      />
      {errors && <p>{errors}</p>}
      {result && <p>{result}</p>}
    </div>
  )
}

Camera.propTypes = {
  onRead: PropTypes.func.isRequired,
}

export default Camera
