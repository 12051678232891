import React from 'react'
import logo from '../../assets/logo_big.png'
import './styles.scss'

const Logo = () => {
  return (
    <header className="logo">
      <img src={logo} alt="Kaikukortti" />
    </header>
  )
}

export default Logo
