/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react'
import Creatable from 'react-select/creatable'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Button,
  Notification,
  DatePicker,
  TimePicker,
} from 'react-rainbow-components'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import * as API from '../../utilities/api'
import './styles.scss'
import SuccessMessage from '../SuccessMessage'
import ErrorMessage from '../ErrorMessage'

const customStyles = {
  menu: provided => ({
    ...provided,
    zIndex: 3,
  }),
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? '#48544c' : base.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #48544c' : base.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? '#48544c;' : base.borderColor,
    },
  }),
  input: base => ({
    ...base,
    padding: '0px',
  }),
}

const EntryForm = ({
  redirect,
  previousEvents = [],
  relatedOperators,
  card,
  handleSetCard,
  handleNavigateBack,
  errors,
  setErrors,
}) => {
  const { register, handleSubmit, setValue, getValues, watch, reset } = useForm({
    defaultValues: {
      children_amount: 0,
      event_date: new Date(),
    },
  })

  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [doRedirect, setDoRedirect] = useState(true)
  const [showInfo, setShowInfo] = useState(true)
  const [newEventName, setNewEventName] = useState(null)
  // Watch selected operator
  const selectedOperator = watch(['operator']) && watch(['operator']).operator
  const [operatorPreviousEvents, setOperatorPreviousEvents] = useState(
    previousEvents
  )

  const eventSelectRef = useRef(
    register(
      { name: 'event' },
      {
        maxLength: 100,
      }
    )
  );

  const operatorSelectRef = useRef(
    register(
      { name: 'operator' },
      {
        maxLength: 300,
      }
    )
  )
  // Watch form value for event_date and pass it to date picker as current date
  const currentDate = watch(['event_date']) && watch(['event_date']).event_date

  const currentTime = watch(['event_time']) && watch(['event_time']).event_time

  const numChildrenOnProfile =
    (card && card.card_profile && card.card_profile.number_of_minors) || 0

  // Get operator related events whenever selected operator changes
  // This is only valid for ticket dealer operators, who need to
  // select operators
  useEffect(() => {
    setErrors({ ...errors, operator: null })
    const getOperatorEvents = async () => {
      try {
        const res = await API.GET('api/events/', { operator: selectedOperator })
        if (res && res.data && res.data.results) {
          const { data } = res
          const events = data.results
            .filter(event => event.active)
            .map(event => ({
              value: event.id,
              label: event.name,
            }))
            .slice(-30)
            .reverse()
          setOperatorPreviousEvents(events)
        } else {
          setOperatorPreviousEvents([])
        }
      } catch (err) {
        setOperatorPreviousEvents([])
      }
    }
    getOperatorEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperator])

  const handleReset = () => {
    reset();

    // Clear the value of the Creatable Select component using setValue
    if (getValues('event')) {
      // Set the value to null to clear the state
      eventSelectRef.current.select.select.setValue(null);
    }

    if (getValues('operator')) {
      // Set the value to null to clear the state
      operatorSelectRef.current.select.setValue(null);
    }
  };

  useEffect(() => {
    const setTimeoutAndRedirect = () => {
      // Show success message for 3 seconds before redirecting
      const timer = setTimeout(() => {
        setSuccess(false)
        // Clear card to be able to redirect after submitting data
        if (doRedirect) {
          handleSetCard(null)
          redirect()
        } else {
          handleReset()
        }
      }, 3000)
      return () => clearTimeout(timer)
    }

    if (success) {
      setTimeoutAndRedirect()
    }
  }, [success, setSuccess, doRedirect, handleSetCard, redirect, handleReset])

  const onSubmit = async (formData, event) => {
    setDoRedirect(true)
    if (relatedOperators && relatedOperators.length > 0 && !formData.operator) {
      setErrors({
        ...errors,
        operator: 'Valitse ensin toimija, jonka tapahtumaan lippu välitetään!',
      })
    } else {
      try {
        const data = {
          ...formData,
          card: card.id,
          new_event: newEventName,
          event_date: moment(formData.event_date).format('YYYY-MM-DD'),
        }

        setLoading(true)
        const apiResp = await API.POST('api/entries/', data)
        if (apiResp && apiResp.status === 201) {
          setSuccess(true)
          setLoading(false)
        } else {
          setErrors({ entry: 'Jokin meni pieleen kirjausta tehdessä!' })
          setLoading(false)
        }
      } catch (e) {
        setErrors({ entry: 'Jokin meni pieleen kirjausta tehdessä!' })
        setLoading(false)
      }
    }
  }

  const onSubmitWithoutRedirect = async (formData, event) => {
    setDoRedirect(false)
    if (relatedOperators && relatedOperators.length > 0 && !formData.operator) {
      setErrors({
        ...errors,
        operator: 'Valitse ensin toimija, jonka tapahtumaan lippu välitetään!',
      })
    } else {
      try {
        const data = {
          ...formData,
          card: card.id,
          new_event: newEventName,
          event_date: moment(formData.event_date).format('YYYY-MM-DD'),
        }
        console.log(data)
        setLoading(true)
        const apiResp = await API.POST('api/entries/', data)
        if (apiResp && apiResp.status === 201) {
          setSuccess(true)
          setLoading(false)
        } else {
          setErrors({ entry: 'Jokin meni pieleen kirjausta tehdessä!' })
          setLoading(false)
        }
      } catch (e) {
        setErrors({ entry: 'Jokin meni pieleen kirjausta tehdessä!' })
        setLoading(false)
      }
    }
  }

  return (
    <div className="entry-form">
      {success && <SuccessMessage msg="Kirjaus tallennettu onnistuneesti!" />}
      <ErrorMessage msg={errors.entry} />

      <Button
        type="button"
        variant="base"
        onClick={handleNavigateBack}
        style={{ width: '100%' }}
      >
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          className="rainbow-m-right_medium"
        />
        Takaisin
      </Button>
      <h1>Tapahtuman kirjaaminen</h1>
      {card && <h3>{card.card_number}</h3>}
      <form onSubmit={handleSubmit(onSubmit)}>
        {relatedOperators && relatedOperators.length > 0 && (
          <label>
            Valitse kulttuuritoimija, jonka tapahtuma on kyseessä
            <Select
              className="creatable-input"
              styles={customStyles}
              isClearable
              placeholder="Kulttuuritoimija"
              aria-label="Kulttuuritoimija"
              options={relatedOperators}
              onChange={selected =>
                setValue('operator', selected ? selected.value : null)
              }
              ref={operatorSelectRef}
            />
          </label>
        )}
        {errors && errors.operator && (
          <div className="rainbow-flex rainbow-flex_column">
            <div className="rainbow-p-bottom_x-small">
              <Notification
                title="Valitse toimija"
                className="entry-form-notification"
                style={{ width: '100%' }}
                description="Toimija on pakollinen tieto."
                icon="error"
                onRequestClose={() => setErrors({ ...errors, operator: null })}
              />
            </div>
          </div>
        )}

        <label>
          Valitse kirjattavan tapahtuman nimi tai luo uusi
          <Creatable
            className="creatable-input"
            isClearable
            styles={customStyles}
            options={operatorPreviousEvents}
            placeholder="Tapahtuman nimi"
            aria-label="Tapahtuman nimi"
            noOptionsMessage={() => 'Ei tapahtumia'}
            formatCreateLabel={inputValue => `Luo ${inputValue}`}
            onChange={(selected, event) => {
              if (event && event.action && event.action === 'create-option') {
                setValue('event', null)
                // New events are handled in the backend different
                // than already created events
                setNewEventName(selected ? selected.value : null)
              } else {
                setValue('event', selected ? selected.value : null)
              }
            }}
            ref={eventSelectRef}
          />
        </label>

        <label htmlFor="num_children">
          Alle 18-vuotiaiden lasten tai lastenlasten määrä
          <input
            type="number"
            min="0"
            max="100"
            placeholder="Alle 18-vuotiaiden lasten tai lastenlasten määrä"
            name="children_amount"
            ref={register({ required: true, maxLength: 100 })}
            id="num_children"
          />
        </label>
        {showInfo && numChildrenOnProfile >= 0 && (
          <div className="rainbow-flex rainbow-flex_column">
            <div className="rainbow-p-bottom_x-small">
              <Notification
                title="Lasten tai lastenlasten määrä"
                className="entry-form-notification"
                style={{ width: '100%' }}
                description={`Asiakkaan alle 18-vuotiaitten lasten tai lastenlasten määrä profiilitietojen perusteella on ${numChildrenOnProfile}`}
                icon="info"
                onRequestClose={() => setShowInfo(false)}
              />
            </div>
          </div>
        )}

        <div>
          <label htmlFor="event_date">
            Tapahtuman päivämäärä
            <DatePicker
              id="datepicker-1"
              value={new Date(currentDate)}
              onChange={value => setValue('event_date', value)}
              formatStyle="medium"
              locale="fi-FI"
              okLabel="Ok"
              cancelLabel="Peruuta"
              ref={() => register({ name: 'event_date' })}
              hour24
            />
          </label>
        </div>

        <div>
          <label htmlFor="event_time">
            Tapahtuman kellonaika
            <TimePicker
              id="timepicker-1"
              value={currentTime}
              onChange={value => setValue('event_time', value)}
              formatStyle="medium"
              locale="fi-FI"
              okLabel="Ok"
              cancelLabel="Peruuta"
              ref={() => register({ name: 'event_time' })}
              hour24
            />
          </label>
        </div>

        <Button
          type="submit"
          variant="brand"
          style={{ width: '100%', lineHeight: '1.5rem', padding: '0.5rem 0' }}
          label="Tallenna ja palaa etusivulle"
          isLoading={loading}
        />
        <Button
          type="button"
          onClick={handleSubmit(onSubmitWithoutRedirect)}
          variant="brand"
          style={{ width: '100%', marginTop: '1rem', lineHeight: '1.5rem', padding: '0.5rem 0' }}
          label="Tallenna ja jatka samalla kortilla"
          isLoading={loading}
        />
      </form>
    </div>
  )
}

EntryForm.propTypes = {
  redirect: PropTypes.func.isRequired,
  previousEvents: PropTypes.arrayOf(PropTypes.object),
  card: PropTypes.shape({
    id: PropTypes.number,
    card_number: PropTypes.string,
    card_profile: PropTypes.shape({
      number_of_minors: PropTypes.number,
    }),
  }),
  handleSetCard: PropTypes.func.isRequired,
  handleNavigateBack: PropTypes.func.isRequired,
  relatedOperators: PropTypes.instanceOf(Array).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  setErrors: PropTypes.func.isRequired,
}

EntryForm.defaultProps = {
  previousEvents: [],
  card: null,
}

export default EntryForm
