/* eslint-disable func-names */
import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL

/*  baseurl ends to /
*   using GET and POST url should be in like path/to/; not /path/to/

/*  queryparams should be an object
*   {param: value}
*   evaluating to url?param=value
*/

const deleteAuthToken = () => {
  localStorage.removeItem('kaikuToken')
}

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error && error.response.status === 401) {
      // Delete auth token from local storege and log out user
      // if signature has expired
      deleteAuthToken()
    }
    return Promise.reject(error)
  }
)

export const setAuthHeader = async (accessToken) => {
  axios.defaults.headers.common.Authorization = `JWT ${accessToken}`
}

export const GET = (url, queryParams = {}, useBaseUrl = true) => {
  axios.defaults.headers.common.Authorization = `JWT ${localStorage.getItem(
    'kaikuToken'
  )}`
  const urlPrefix = useBaseUrl ? baseUrl : ''
  return axios.get(urlPrefix + url, {
    params: queryParams,
  })
}

export const DELETE = (url, queryParams = {}, useBaseUrl = true) => {
  axios.defaults.headers.common.Authorization = `JWT ${localStorage.getItem(
    'kaikuToken'
  )}`
  const urlPrefix = useBaseUrl ? baseUrl : ''

  return axios.delete(urlPrefix + url, {
    params: queryParams,
  })
}

export const POST = (url, body, useBaseUrl = true) => {
  axios.defaults.headers.common.Authorization = `JWT ${localStorage.getItem(
    'kaikuToken'
  )}`
  const urlPrefix = useBaseUrl ? baseUrl : ''
  return axios.post(urlPrefix + url, body)
}
