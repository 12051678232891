import React from 'react'
import PropTypes from 'prop-types'
import Camera from '../Camera'
import './styles.scss'

const Scanner = ({ onRead, title }) => {
  return (
    <div className="scanner">
      <h2>{title}</h2>
      <Camera onRead={onRead} />
    </div>
  )
}

Scanner.propTypes = {
  onRead: PropTypes.func.isRequired,
  title: PropTypes.string,
}

Scanner.defaultProps = {
  title: '',
}

export default Scanner
