import React, { useState } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import { Application } from 'react-rainbow-components'
import CultureOperatorView from './components/CultureOperatorView'
import LoginView from './components/LoginView'
import Entry from './components/Entry'
import Footer from './components/Footer'
import Logo from './components/Logo'
import OldEntries from './components/OldEntriesList'
import './App.scss'

const ProtectedRoute = ({ component: ProtectedComponent, login, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!login) {
        return (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
      return <ProtectedComponent {...props} {...rest} />
    }}
  />
)

const theme = {
  rainbow: {
    palette: {
      brand: '#5b7065',
      mainBackground: '#fff',
    },
  },
}

const App = () => {
  // QR scanner result
  const [result, setResult] = useState('')
  // Current card
  const [card, setCard] = useState(null)
  // Current errors or warnings to show
  const [errors, setErrors] = useState({})
  // Current info messages to show
  const [infoMessages, setInfoMessages] = useState({})

  const isLoggedIn = () =>
    localStorage.getItem('kaikuToken') &&
    localStorage.getItem('kaikuToken').length > 0

  return (
    <Application theme={theme}>
      <div className="grid-container">
        <Logo />
        <Router>
          {!isLoggedIn() ? <Redirect to="/" /> : <Redirect to="/operator" />}
          <Switch>
            <Route exact path="/">
              <LoginView
                setResult={(value) => {
                  setResult(value)
                }}
                errors={errors}
                setErrors={setErrors}
              />
            </Route>
            <ProtectedRoute
              exact
              login={isLoggedIn()}
              path="/operator"
              component={CultureOperatorView}
              result={result}
              handleSetCard={setCard}
              card={card}
              setErrors={setErrors}
              errors={errors}
              infoMessages={infoMessages}
              setInfoMessages={setInfoMessages}
            />
            <ProtectedRoute
              exact
              login={isLoggedIn()}
              path="/operator/entry"
              component={Entry}
              card={card}
              handleSetCard={setCard}
              errors={errors}
              setErrors={setErrors}
              infoMessages={infoMessages}
              setInfoMessages={setInfoMessages}
            />
            <ProtectedRoute
              exact
              login={isLoggedIn()}
              path="/operator/entries"
              component={OldEntries}
            />
          </Switch>
        </Router>
        <Footer />
      </div>
    </Application>
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  login: PropTypes.bool.isRequired,
}

export default App
