import React from 'react'
import './styles.scss'

const Footer = () => {
  return (
    <footer className="footer">
      <p
        style={{
          color: '#6d6f71',
          fontSize: '11px',
        }}
      >
        Kaikukortti © Kulttuuria kaikille
      </p>
    </footer>
  )
}

export default Footer
